<template>
  <div class="reset-pass code">
    <div class="mt-2 mb-5 text-center">
      <h1 class="forget-title">{{ $t('g.resetPassword') }}</h1>
      <p class="forget-desc">{{ $t('g.pleaseEnterTheCodeWeSentTo') }}</p>
      <p class="forget-email-desc">{{ email }}</p>
    </div>

    <validation-observer ref="checkCodeValidation" class="d-flex justify-content-center align-items-center">
      <b-form class="forget-password-code-form" @submit.prevent>
        <b-form-group label-for="forget-password-code" class="d-flex justify-content-center">
          <validation-provider #default="{ errors }" name="Code" rules="required">
            <b-form-input
                v-for="(digit, index) of digits"
                :key="index"
                type="text"
                maxlength="1"
                pattern="[0-9]"
                class="code-digit"
                v-model="form_data[`${digit}_digit`]"
                :state="errors.length > 0 ? false : null"
                :name="`${digit}_digit`"
                placeholder="_"
                @input="handleInput(index)"
                @click="(e) => e.target.select()"
                ref="inputFields"
            />
            <small class="text-danger d-flex justify-content-center">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-button
            type="submit"
            variant="primary"
            block
            @click="checkCodeValidationForm"
            class="primary-btn"
            :disabled="isPending"
        >
          <div v-if="isPending" class="loading_" data-loading="light"/>
          <span v-else>{{ $t('g.confirm') }}</span>
        </b-button>

        <!-- change email button -->
        <b-button
            type="button"
            variant="primary"
            block
            @click="$emit('changeStage', 1)"
            class="outline-primary-btn btn-sec"
        >
          <span><small>{{ $t('g.changeEmail') }}</small></span>
        </b-button>
      </b-form>
    </validation-observer>

    <!-- Send Another Code Again -->
    <div class="auth-footer-btn d-flex justify-content-center">
      <b-button @click="this.sendAnotherEmailCode" variant="none" class="sing-btn">
        <small>{{ $t('g.sendAnotherCodeAgain') }}</small>
      </b-button>
    </div>
    <!-- /Send Another Code Again -->
  </div>
</template>

<script>
import { BButton, BForm, BFormGroup, BFormInput, BLink } from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import toast from '@/libs/toastification'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@core/utils/validations/validations'

export default {
  name: 'CheckCode',
  components: {
    ValidationProvider,
    ValidationObserver,
    required,
    BLink,
    BFormInput,
    BButton,
    BForm,
    BFormGroup
  },
  props: {
    email: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      digits: ['first', 'second', 'third', 'fourth', 'fifth', 'sixth'],
      isPending: false,
      form_data: {
        first_digit: '',
        second_digit: '',
        third_digit: '',
        fourth_digit: '',
        fifth_digit: '',
        sixth_digit: '',
        pin_code: '',
      },
    }
  },
  methods: {
    handleInput(index) {
      const nextIndex = index + 1
      const inputFields = this.$refs.inputFields
      if (inputFields[nextIndex]) {
        inputFields[nextIndex].focus()
      } else {
        this.form_data.pin_code = this.digits.reduce((pin, digit) => {
          return pin + this.form_data[`${digit}_digit`]
        }, '')
        inputFields[index].blur()
      }
    },
    checkCodeValidationForm() {
      this.$refs.checkCodeValidation.validate()
          .then((success) => {
            if (success) {
              this.isPending = true
              const formData = new FormData()
              formData.append('email', this.email)
              formData.append('pin_code', parseInt(this.form_data.pin_code))
              this.$http
                  .post('auth/student/reset/check_code', formData)
                  .then((res) => {
                    if (res.status === 200) {
                      this.$emit('changeSecondStageData', this.form_data.pin_code)
                      toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Welcome`,
                          icon: 'CheckCircleIcon',
                          variant: 'success',
                          text: this.$t('g.codeIsCorrect'),
                        },
                      })
                    }
                  })
                  .catch((err) => {
                    console.log(err)
                    toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: `Error`,
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: this.$t('g.codeIsIncorrect'),
                      },
                    })
                  })
                  .finally(() => {
                    this.isPending = false
                  })
            }
          })
    },
    sendAnotherEmailCode() {
      const formData = new FormData()
      formData.append('email', this.email)
      this.$http
          .post('auth/student/check-email', formData)
          .then((res) => {
            if (res.status === 200) {
              toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Welcome`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: this.$t('g.codeSentSuccessfully'),
                },
              })
            }
          })
          .catch((err) => {
            console.log(err)
            toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: this.$t('g.codeSentError'),
              },
            })
          })
          .finally(() => {
            this.isPending = false
          })
    },
  }
}
</script>

<style lang="scss" src="./_checkCode.scss" scoped/>
