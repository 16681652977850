<template>
  <div class="reset-pass email">
    <div class="mt-2 mb-5 text-center">
      <h1 class="forget-title">{{ $t("g.resetPassword") }}</h1>
      <p class="forget-desc">{{ $t("g.pleaseEnterYourEmailAddress") }}</p>
    </div>

    <validation-observer
      ref="emailCodeValidation"
      class="d-flex justify-content-center align-items-center"
    >
      <b-form class="forget-password-form" @submit.prevent>
        <b-form-group label-for="forget-password-email">
          <validation-provider
            #default="{ errors }"
            name="Email"
            rules="required|email"
          >
            <b-form-input
              id="forget-password-email"
              v-model="email"
              :state="errors.length > 0 ? false : null"
              name="forget-password-email"
              :placeholder="$t('g.email')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-button
          type="submit"
          variant="primary"
          @click="emailCodeValidationForm"
          class="primary-btn"
          :disabled="isPending"
        >
          <div v-if="isPending" class="loading_" data-loading="light" />
          <span v-else>{{ $t("g.sendCode") }}</span>
        </b-button>
      </b-form>
    </validation-observer>

    <!-- Login Link-->
    <div class="auth-footer-btn d-flex justify-content-center">
      <b-link :to="{ name: 'Login' }" class="sing-btn">
        <small>{{ $t("g.backToLogin") }}</small>
      </b-link>
    </div>
    <!-- /Login Link-->
  </div>
</template>

<script>
import { BButton, BForm, BFormGroup, BFormInput, BLink } from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import toast from "@/libs/toastification";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { email, required } from "@core/utils/validations/validations";

export default {
  name: "SendEmailCode",
  components: {
    ValidationProvider,
    ValidationObserver,
    required,
    email,
    BLink,
    BFormInput,
    BButton,
    BForm,
    BFormGroup,
  },
  data() {
    return {
      email: "",
      isPending: false,
    };
  },
  methods: {
    emailCodeValidationForm() {
      this.$refs.emailCodeValidation.validate().then((success) => {
        if (success) {
          this.isPending = true;
          const formData = new FormData();
          formData.append("email", this.email);
          this.$http
            .post("auth/student/check-email", formData)
            .then((res) => {
              if (res.status === 200) {
                this.$emit("changeFirstStageData", this.email);
                toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Welcome`,
                    icon: "CheckCircleIcon",
                    variant: "success",
                    text: this.$t("g.verifyCodeSent"),
                  },
                });
              }
            })
            .catch((err) => {
              console.log(err);
              toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error`,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                  text: this.$t("g.verifyCodeError"),
                },
              });
            })
            .finally(() => {
              this.isPending = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" src="./_sendEmailCode.scss" scoped />
